import './bootstrap';
import { browserTracingIntegration, BrowserClient, browserProfilingIntegration, makeFetchTransport, defaultStackParser, getCurrentScope } from "@sentry/browser";

const sentry = new BrowserClient({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    environment: import.meta.env.VITE_APP_ENV,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: [
        browserTracingIntegration({
            enableInp: true,
        }),
        browserProfilingIntegration(),
    ],
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['bajubodo.com', 'www.bajubodo.com'],
});

getCurrentScope().setClient(sentry);
sentry.init();

var numericInputs = document.querySelectorAll('.numeric');

numericInputs.forEach(function (input) {
    input.addEventListener('keyup', function () {
        var value = input.value;
        value = value.replace(/[^0-9]/g, '');
        input.value = value ? parseInt(value, 10) : '';
    });

    input.addEventListener('blur', function () {
        var value = input.value; W
        value = value.replace(/[^0-9]/g, '');
        input.value = value ? parseInt(value, 10) : '';
    });
});
